import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Input } from 'antd';
import { INSTAGRAM, TIKTOK, TWITTER } from '@/shared/constants/links';
import { Store } from '@/shared/types/store.interface';
import { getExternalField } from '@/shared/utils/store-helper';
import { ExternalFieldName } from '@/shared/types/marketplace.interface';
import { useConfigProvider } from '@/lib/context/ConfigProvider';
import './index.less';

interface PasswordProtectionProps {
  store: Store;
}

interface SocialLogo {
  filename: string;
  key: string;
  link: string;
}

const SOCIAL_LOGOS: SocialLogo[] = [
  {
    filename: 'instagram-cream.svg',
    key: 'Instagram',
    link: INSTAGRAM,
  },
  {
    filename: 'twitter-cream.svg',
    key: 'Twitter',
    link: TWITTER,
  },
  {
    filename: 'tiktok-cream.svg',
    key: 'TikTok',
    link: TIKTOK,
  },
];

const PasswordProtection = ({ store }: PasswordProtectionProps) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const { setUnlockedStores, unlockedStores } = useConfigProvider();
  const storePassword = getExternalField(store, ExternalFieldName.STOREFRONT_PASSWORD);

  const unlock = () => {
    if (inputValue === storePassword) {
      setUnlockedStores([...unlockedStores, store.id]);
    } else {
      setShowError(true);
    }
  };

  const onInput = (value: string) => {
    if (showError) {
      setShowError(false);
    }
    setInputValue(value);
  };

  return (
    <div className="password-protection">
      <div className="content">
        <Image
          className="logo"
          src={store?.storeFrontAvatarUrl ?? ''}
          alt={store?.storeFrontTitle}
          title={store?.storeFrontTitle}
          width={136}
          height={136}
        />

        <span className="title">
          <span>{store?.storeFrontTitle}</span> <span>Private Wholesale Storefront</span>
        </span>

        <div className="input-container">
          <Input
            className="password-input"
            placeholder="Please enter the password"
            value={inputValue}
            onChange={(event) => onInput(event.target.value)}
            type="password"
            onPressEnter={unlock}
          />
          <div className="unlock-button" onClick={unlock}>
            <span>Unlock</span>
          </div>

          {showError && (
            <div className="error-message-container">
              <span>
                Incorrect password. Please contact{' '}
                <span className="underline">creators@pietrastudio.com</span> if you have any issues.
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="footer">
        <span className="footer-text">Everything you need to start and scale your business.</span>
        <div className="branding">
          <Link href="/" className="logo">
            <Image
              className="pietra"
              src="/images/pietra-logos/pietra-logo-cream.svg"
              alt="Pietra"
              title="Pietra"
              width={40}
              height={11}
            />
          </Link>

          <div className="social-links">
            {SOCIAL_LOGOS.map((item: SocialLogo) => {
              return (
                <Link key={item.key} href={item.link} target="_blank" className="logo">
                  <Image
                    src={`/images/social-logos/${item.filename}`}
                    alt={item.key}
                    title={item.key}
                    width={14}
                    height={14}
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordProtection;

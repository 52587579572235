import { IxParams } from '@/shared/types/typings';
import {
  SearchSuggestions,
  SuggestionsCategories,
  SuggestionsData,
} from '@/shared/types/marketplace.interface';
import dayjs from 'dayjs';
import dayjsBusinessDays from 'dayjs-business-days';
import { isString } from 'lodash';
import { BLACK, CHARCODE_ENTER, PATTERN_EMAIL, WHITE } from '../constants/app';
import { Store } from 'antd/es/form/interface';
import { PlanType } from '../types/store.interface';

dayjs.extend(dayjsBusinessDays);

export const isS3Image = (url: string) => {
  return url?.includes('static.pietrastudio.com') ?? false;
};
export function imgix(v: string, params?: IxParams) {
  if (!v || !isS3Image(v)) return v || '';
  const url = new URL(v);
  url.host = 'pietra.imgix.net';
  if (!params?.auto) {
    url.searchParams.append('auto', 'compress,format');
  }
  if (params) {
    for (const [k, v] of Object.entries(params)) {
      url.searchParams.append(k, v);
    }
  }
  return url.toString();
}

export const isVideo = (url: string) => {
  return isString(url) && (url.endsWith('.mp4') || url.endsWith('.mov'));
};

export const isEmailValid = (email: string = ''): boolean => {
  return PATTERN_EMAIL.test(email);
};

export const isMobileByUserAgent = (userAgent: string) => {
  return Boolean(
    userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
  );
};
export const capitalizeString = (text: string) => {
  return (text || '')
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

type transformType =
  | 'pico'
  | 'icon'
  | 'thumb'
  | 'small'
  | 'compact'
  | 'medium'
  | 'large'
  | 'grande'
  | '1024'
  | '2048';

/**
 * @link https://www.evernote.com/l/AT59JlZLi4xFW72l5E6dvv2Jtt8eHEOPbrY
 * @param url
 * @param size
 */
export function imageTransform(url: string, size: transformType) {
  if (typeof url !== 'string') {
    return url;
  }

  const IMAGE_SIZE: { [key: string]: number[] } = {
    pico: [16, 16],
    icon: [32, 32],
    thumb: [50, 50],
    small: [100, 100],
    compact: [160, 160],
    medium: [240, 240],
    large: [480, 480],
    grande: [600, 600],
    '1024': [1024, 1024],
    '2048': [2048, 2048],
  };

  const pathArray = url.split('/');
  if (!/(s3\.amazonaws\.com)|(static\.pietrastudio\.com)/.test(pathArray[2])) {
    return url;
  }

  const lastIndex = pathArray.length - 1;
  if (!/^(jpg|png|jpeg)/i.test(pathArray[lastIndex].split('.')[1])) {
    return url;
  }

  const targetIndex = pathArray.findIndex((v) => /^public/.test(v));
  if (targetIndex === -1) {
    return url;
  }

  const newURL = new URL(url);
  newURL.hostname = 'd151315muslonm.cloudfront.net';
  newURL.searchParams.set('format', 'auto');
  newURL.searchParams.set('width', IMAGE_SIZE[size][0].toString());
  newURL.searchParams.set('height', IMAGE_SIZE[size][1].toString());
  return newURL.toString();
}

export const convertSuggestions = (
  data: SuggestionsData,
  subcategoryValueMap: any,
): SearchSuggestions => {
  const arr: SearchSuggestions = {
    stores: [],
    items: [],
    categories: [],
  };
  if (data.storeList) {
    arr.stores = data.storeList;
  }
  // TODO <editor-fold desc="Suspicious unused code">
  const allCategories: SuggestionsCategories[] = [];
  if (data.categories) {
    data.categories.forEach(function (item) {
      allCategories.push({
        text: subcategoryValueMap[item] || item,
        value: item,
        param: 'category',
      });
    });
  }
  if (data.materials) {
    data.materials.forEach(function (item) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: 'material',
      });
    });
  }
  if (data.gemstones) {
    data.gemstones.forEach(function (item) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: 'gemstone',
      });
    });
  }
  arr.categories = allCategories.length > 4 ? allCategories.slice(0, 4) : allCategories;
  // </editor-fold>
  if (data.list) {
    arr.items = data.list.map((text) => ({ text }));
  }
  return arr;
};

export const shopAllLink = (title: string, params: any): string => {
  if (params.exactRoute) {
    return params.exactRoute;
  }
  const urlPath = title ? new URLSearchParams({ ...params, title }) : '';
  return `/shop/all?${urlPath}`;
};

export const zeroPaddedNumber = (num: number): string => {
  return `${num}`.padStart(2, '0');
};

export const formatNumberWithCommas = (num: number) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatPrice = (price: any): string => {
  if (!price) return '';

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
    parseFloat(price),
  );
};

// Public Domain / MIT
// Taken from https://www.simoahava.com/analytics/13-useful-custom-dimensions-for-google-analytics/#1-2-session-id
export const generateUniqueSessionId = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // Use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const getDateFromNow = (days: number, businessDaysOnly: boolean) => {
  if (businessDaysOnly) {
    // https://github.com/soar-tech/dayjs-business-days
    // @ts-ignore:next-line
    return dayjs().businessDaysAdd(days).format('dddd, MMMM D');
  }
  return dayjs().add(days, 'day').format('dddd, MMMM D');
};

export const getBusinessDayFromNow = (days: number) => getDateFromNow(days, true);

export const fixLineBreaksHtml = (str: string) => {
  return str.replace(/\n/g, '<br/>');
};
export const sanitizeHtml = (text: string) => {
  if (!text) {
    return '';
  }
  text = text.replace(/h1/g, 'span');
  text = text.replace(/h2/g, 'span');
  text = text.replace(/h3/g, 'span');
  text = text.replace(/h4/g, 'span');
  text = text.replace(/h5/g, 'span');
  const removeLinksRegex = /<a.*?>/g;
  text = text.replace(removeLinksRegex, '');
  const removeStylingRegex = /style=.*?".*?"/g;
  text = text.replace(removeStylingRegex, '');
  return text;
};

export const convertSuggestionsResponse = (
  response: any,
  subcategoryValueMap: { [key: string]: string },
) => {
  const arr: {
    stores: any[];
    items: any[];
    categories: any[];
  } = {
    stores: [],
    items: [],
    categories: [],
  };
  if (response.storeList) {
    arr.stores = response.storeList;
  }
  // TODO <editor-fold desc="Suspicious unused code">
  const allCategories: Array<{ [key: string]: any }> = [];
  if (response.categories) {
    response.categories.forEach(function (item: string) {
      allCategories.push({
        text: subcategoryValueMap[item] || item,
        value: item,
        param: 'category',
      });
    });
  }
  if (response.materials) {
    response.materials.forEach(function (item: string) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: 'material',
      });
    });
  }
  if (response.gemstones) {
    response.gemstones.forEach(function (item: string) {
      allCategories.push({
        text: capitalizeString(item),
        value: item,
        param: 'gemstone',
      });
    });
  }
  arr.categories = allCategories.length > 4 ? allCategories.slice(0, 4) : allCategories;
  // </editor-fold>
  if (response.list) {
    arr.items = response.list.map((text: string) => ({ text }));
  }
  return arr;
};

export function isHotkeyCommandEnter(event: any) {
  return event.which === CHARCODE_ENTER;
}

export function hexToRgba(hex: string, opacity: number = 1) {
  // Convert color hex to RGB format
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  // Computes the RGBA value of a color
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export const pickTextColorBasedOnBgColor = (bgColor: string) => {
  const backgroundColor = bgColor || WHITE;
  const color =
    backgroundColor.charAt(0) === '#' ? backgroundColor.substring(1, 7) : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 150 ? BLACK : WHITE;
};

export function scrollToTop(elemClass: string) {
  const elems = document.querySelectorAll(elemClass);
  if (elems) {
    elems[0]?.scrollIntoView();
  }
}

export const hasUnlockedPlatform = (store: Store) => {
  return store?.planType !== PlanType.TIER_1;
};
